import React, {useCallback} from 'react';
import {Number} from 'components/customer/Product/QuickAdd/NumberSpinner';
import {useQuickAddContext} from 'components/customer/Product/QuickAdd';
import {Form} from 'react-bootstrap';
import styled from 'styled-components';
import {mobile} from 'shared/Breakpoints';

interface WidthProps {
    id?: string;
}

export const Width = ({id}: WidthProps) => {
    const {
        width,
        setWidth,
        item,
        validationMessage,
        isFocused,
        setValidationMessage,
    } = useQuickAddContext();

    const changeHandler = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = parseFloat(event.target.value);
            setWidth(newValue);
            setValidationMessage('');
        },
        [setWidth]
    );

    return (
        <Form.Group>
            <Number
                id={id}
                value={width}
                onChange={changeHandler}
                data-cy={item ? `width-${item.id}` : 'width'}
                type="number"
                isInvalid={validationMessage != ''}
            />
            {isFocused ? (
                <CustomFeedback type="invalid" tooltip>
                    {validationMessage}
                </CustomFeedback>
            ) : null}
        </Form.Group>
    );
};

const CustomFeedback = styled(Form.Control.Feedback)`
    right: 0;
    left: 0;
    top: calc(100% + 2px);

    ${mobile`
        top: auto;
        bottom: -130px;
    `}
`;
