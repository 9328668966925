import React, {useCallback} from 'react';
import styled from 'styled-components';
import {NumberSpinner} from 'components/customer/Product/QuickAdd/NumberSpinner';
import {Width} from 'components/customer/Product/QuickAdd/Width';
import {HardwareButton} from 'shared/components/Product/Item';
import {useQuickAddContext} from 'components/customer/Product/QuickAdd';
import {mobile} from 'shared/Breakpoints';

export const Controls = () => {
    const {open, loading, addProduct, item, validationMessage} =
        useQuickAddContext();

    const clickHandler = useCallback(() => {
        void addProduct();
    }, [addProduct]);

    return (
        <Container $display={open} $transparent={loading}>
            <NumberSpinner />
            <WidthContainer>
                <Width id={`width-${item.id}`} />
                <label htmlFor={`width-${item.id}`}>Width</label>
            </WidthContainer>
            <HardwareButton
                data-cy="quick-add-button"
                $margin={false}
                disabled={loading || validationMessage !== ''}
                onClick={clickHandler}
                className="button-blue btn-sm"
                iconName="Button-Add.svg">
                Add
            </HardwareButton>
        </Container>
    );
};

const WidthContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 49px !important;

    > div {
        flex: 1;
    }

    > label {
        font-size: 0.75em;
        font-weight: 500;
        color: ${({theme}) => theme.colors.secondary.main};
    }
`;

const Container = styled.div<{$display: boolean; $transparent: boolean}>`
    visibility: ${({$display}) => ($display ? 'visible' : 'hidden')};
    opacity: ${({$transparent}) => ($transparent ? 0.5 : 1)};
    padding-top: 5px;
    display: flex;
    height: 35px;
    gap: 5px;

    ${mobile`
        flex-wrap: wrap;
        justify-content: center;
        align-items: start;

        > div {
            flex-basis: 50%;
            height: 30px;
        }

        > div:first-child {
            flex-basis: 100%;
            padding: 0 35px;
        }
    `}
`;
